import {
  Meditation,
  Macroeconomics,
  Existentialism,
  Negotiation,
  Nutrition,
  Productivity,
  OnboardingName,
  OnboardingComplete,
  Quote1,
  Quote2,
  Quote3,
  Quote4,
  History,
  Philosophy,
  Business,
  Leadership,
  Psychology,
  Politics,
  ProductivityIcon,
  Art,
  Design,
  Economics,
} from ".";

export const questionData = [
  {
    question: "Welcome to Imprint!",
    answer_type: "lotti",
    require_input: false,
    answers: [OnboardingName],
    answer_key: "startingSlide",
    subtext: "To get started, answer a few quick questions to help us personalize your experience.",
  },
  {
    question: "What is your age?",
    answer_type: "select",
    require_input: true,
    answers: ["15-24", "25-34", "35-44", "45-54", "55+", "Prefer not to say"],
    answer_key: "age",
  },
  {
    question: "What are your favorite ways to learn?",
    answer_type: "multiselect_two_row",
    require_input: true,
    answers: ["Books", "Audiobooks", "Podcasts", "Videos", "Courses", "Articles"],
    answer_key: "howDoYouLearn",
  },
  {
    question: "Which of these topics interest you?",
    answer_type: "multiselect",
    require_input: true,
    answers: ["History", "Philosophy", "Business", "Leadership", "Psychology"],
    icons: [History, Philosophy, Business, Leadership, Psychology],
    answer_key: "whichTopicsDoYouWant_1",
  },
  {
    question: "Which of these topics interest you?",
    answer_type: "multiselect",
    require_input: true,
    answers: ["Politics", "Productivity", "Art", "Design", "Economics"],
    icons: [Politics, ProductivityIcon, Art, Design, Economics],
    answer_key: "whichTopicsDoYouWant_2",
  },
  {
    question: "Do you agree with this statement?",
    answer_type: "boolean",
    require_input: true,
    answers: [Quote1],
    answer_key: "doYouAgree_rememberBetter",
  },
  {
    question: "Do you agree with this statement?",
    answer_type: "boolean",
    require_input: true,
    answers: [Quote2],
    answer_key: "doYouAgree_spaceOut",
  },
  {
    question: "Do you agree with this statement?",
    answer_type: "boolean",
    require_input: true,
    answers: [Quote3],
    answer_key: "doYouAgree_lessTimeOnSocialMedia",
  },
  {
    question: "Do you agree with this statement?",
    answer_type: "boolean",
    require_input: true,
    answers: [Quote4],
    answer_key: "doYouAgree_moreTimeToReadBooks",
  },
  {
    question: "Which do you want to learn about more?",
    answer_type: "image_select",
    require_input: true,
    answers: ["Meditation", "Macroeconomics"],
    icons: [Meditation, Macroeconomics],
    answer_key: "topicSelection_meditationOrMacroeconomics",
  },
  {
    question: "Which do you want to learn about more?",
    answer_type: "image_select",
    require_input: true,
    answers: ["Existentialism", "Negotiation"],
    icons: [Existentialism, Negotiation],
    answer_key: "topicSelection_existentialismOrNegotiation",
  },
  {
    question: "Which do you want to learn about more?",
    answer_type: "image_select",
    require_input: true,
    answers: ["Nutrition", "Productivity"],
    icons: [Nutrition, Productivity],
    answer_key: "topicSelection_nutritionOrProductivity",
  },
  {
    question: "",
    answer_type: "lotti",
    require_input: false,
    answers: [OnboardingComplete],
    answer_key: "welcome",
  },
];

export const questionData2 = [
  {
    question: "Welcome to Imprint!",
    answer_type: "lotti",
    require_input: false,
    answers: [OnboardingName],
    answer_key: "startingSlide",
    subtext: "To get started, answer a few quick questions to help us personalize your experience.",
  },
  {
    question: "What is your age?",
    answer_type: "select",
    require_input: true,
    answers: ["15-24", "25-34", "35-44", "45-54", "55+", "Prefer not to say"],
    answer_key: "age",
  },
  {
    question: "What are your favorite ways to learn?",
    answer_type: "multiselect_two_row",
    require_input: true,
    answers: ["Books", "Audiobooks", "Podcasts", "Videos", "Courses", "Articles"],
    answer_key: "howDoYouLearn",
  },
  {
    question: "Which of these topics interest you?",
    answer_type: "multiselect",
    require_input: true,
    answers: ["History", "Philosophy", "Business", "Leadership", "Psychology"],
    icons: [History, Philosophy, Business, Leadership, Psychology],
    answer_key: "whichTopicsDoYouWant_1",
  },
  {
    question: "Which of these topics interest you?",
    answer_type: "multiselect",
    require_input: true,
    answers: ["Politics", "Productivity", "Art", "Design", "Economics"],
    icons: [Politics, ProductivityIcon, Art, Design, Economics],
    answer_key: "whichTopicsDoYouWant_2",
  },
  {
    question: "",
    answer_type: "lotti",
    require_input: false,
    answers: [OnboardingComplete],
    answer_key: "welcome",
  },
];
