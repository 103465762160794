/* eslint-disable @typescript-eslint/no-unused-vars */
import anime from "animejs";
import * as React from "react";
import { useEffect } from "react";
import { Questions } from "../../components/PageSegments/onboarding";
import { questionData2 } from "../../content/assets/questionData";
import { BasicLayout } from "../../layouts/BasicLayout";
import { onboarding_questions_start } from "../../services/mixpanel/mixpanel";
import { storeUTMCookies } from "../../util/misc";

export default ({ data, location }) => {
  const textTransition = React.createRef() as any;
  // const shortFlow = location.state?.shortFlow;
  const { search } = location;

  console.log("hit here on email notification ");
  useEffect(() => {
    onboarding_questions_start();
    if (search) {
      storeUTMCookies(search);
    }

    handleAnimation();
  });

  const handleAnimation = () => {
    anime({
      targets: textTransition.current,
      duration: 500,
      translateX: [0, 15],
      opacity: [0.2],
      direction: "reverse",
      easing: "linear",
    });
  };

  return (
    <BasicLayout>
      <Questions data={questionData2}/>
    </BasicLayout>
  );
};
